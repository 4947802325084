import React, { useState, useEffect,useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink } from 'react-scroll';

const Navbar = ({navbarHeight,setNavbarHeight}) => {
  const [click, setClick] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  
  const handleClick = () => setClick(!click);
  const navbarRef = useRef(null);

  const changeNavbarBackground = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    const handleNavbarHeight = () => {
        if (navbarRef.current) {
          setNavbarHeight(navbarRef.current.clientHeight);
        }
      };
  
      // Calculate the navbar height initially and on window resize
      handleNavbarHeight();
      window.addEventListener("resize", handleNavbarHeight);
      window.addEventListener("scroll", changeNavbarBackground);
      // Cleanup
      return () => {
        window.removeEventListener("resize", handleNavbarHeight);
        window.removeEventListener("scroll", changeNavbarBackground);
      };

    
    

  }, []);

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <div
    ref={navbarRef}
      className={`fixed w-full  top-0 left-0 z-50 transition duration-500 ${
        scrollNav ? "bg-black bg-opacity-90" :"bg-transparent"
      }`}
      
      
    >
      <nav className="flex justify-between items-center max-w-mw mx-auto px-6 md:px-20 ">
        <a href="/" className="flex-shrink-0">
          <img src="/Slogo.png" alt="logo" className="w-16 h-auto" />
        </a>

        <div className="md:hidden" onClick={handleClick}>
          {click ? (
            <FaTimes className="text-white text-2xl" />
          ) : (
            <FaBars className="text-white text-2xl" />
          )}
        </div>

        <ul
          className={`flex flex-col md:flex-row md:items-center top-20 absolute md:static right-0 w-full md:w-auto transition-transform duration-300 bg-black md:bg-transparent ${
            click ? "flex" : "hidden md:flex"
          }`}
        >
             <li className="md:px-4 font-medium text-white">
           
           
            <ScrollLink
              to="home"
              smooth={true}
              duration={500}
              spy={true}
              onSetActive={handleSetActive}
              className={`block py-2 md:py-0 cursor-pointer transition duration-500 ${activeSection === "home" ? "text-orange-400 border-b-4 border-orange-400" : "hover:text-orange-400 hover:border-b-4 hover:border-orange-400"}`}
            >
              Home
            </ScrollLink>
            
          </li>
          
          <li className="md:px-4 font-medium text-white">
            <ScrollLink
              to="service"
              smooth={true}
              duration={500}
              spy={true}
              onSetActive={handleSetActive}
              className={`block py-2 md:py-0 cursor-pointer transition duration-500 ${activeSection === "service" ? "text-orange-400 border-b-4 border-orange-400" : "hover:text-orange-400 hover:border-b-4 hover:border-orange-400"}`}
            >
              Services
            </ScrollLink>
          </li>
          <li className="md:px-4 font-medium text-white">
            <ScrollLink
              to="blog"
              smooth={true}
              duration={500}
              spy={true}
              onSetActive={handleSetActive}
              className={`block py-2 md:py-0 cursor-pointer transition duration-500 ${activeSection === "blog" ? "text-orange-400 border-b-4 border-orange-400" : "hover:text-orange-400 hover:border-b-4 hover:border-orange-400"}`}
            >
              Blog
            </ScrollLink>
          </li>
          <li className="md:px-6 font-medium text-white">
            <ScrollLink
              to="contact_us"
              smooth={true}
              duration={500}
              spy={true}
              onSetActive={handleSetActive}
              className={`block py-2 md:py-0 cursor-pointer transition duration-500 ${activeSection === "contact_us" ? "text-orange-400 border-b-4 border-orange-400" : "hover:text-orange-400 hover:border-b-4 hover:border-orange-400"}`}
            >
              Contact Us
            </ScrollLink>
          </li>
          <li className="md:px-4 font-medium text-white">
  <a href="/donate">
    <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-black hover:border-orange-600 hover:border-2 active:bg-orange-600 active:border-orange-700">
      Donate Us
    </button>
  </a>
</li>

        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
