import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import FontAwesome

function Footer() {
  const [email, setEmail] = useState("");
  const handleSubscribe = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("entry.1508482364", email); // Replace with actual entry ID for Email field

    const formUrl = process.env.REACT_APP_NEWSLETTER_LINK; // Replace FORM_ID with your actual Google Form ID

    try {
      const response = await fetch(formUrl, {
        method: "POST",
        body: formData,
        mode: "no-cors", // Make sure to handle CORS appropriately
      });

      setEmail("");

      alert("Form Submitted");

      if (!response.ok) {
        throw new Error("Form submission failed");
      }

      // Reset form fields after successful submission

      console.log("Form submitted successfully:", response);
      // Handle success or further processing here
    } catch (error) {}
  };
  return (
    <footer className="bg-black text-white overflow-x-auto">
      <div className="mx-auto px-4 md:flex md:justify-around  py-8 max-w-mw ">
        <div>
          <img src="/Slogo.png" alt="Logo" className="mb-4 w-24 h-auto" />
          <div className="flex items-center mb-2">
            <i className="fas fa-map-marker-alt mr-2"></i>
            <a
              href="https://g.co/kgs/ahcWQGt"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:underline"
            >
              No.30A, Kannadasan Street, Parvathi Nagar, Old Perungalathur,
              Chennai - 600 063.
            </a>
          </div>
          <div className="flex items-center mb-2">
            <i className="fas fa-envelope mr-2"></i>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=sriramanujarillamtrust@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:underline"
            >
              sriramanujarillamtrust@gmail.com
            </a>
          </div>
          <div className="flex items-center mb-2">
            <i className="fas fa-phone mr-2"></i>
            <a href="tel:+919688343137" className="text-white hover:underline">
              +91 96883 43137
            </a>
          </div>
        </div>

        <div>
          <h4 className="font-bold mb-4">Newsletter</h4>
          <p>Subscribe to our newsletter to get latest updates.</p>
          <form className="mt-2" onSubmit={handleSubscribe}>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              className="p-2 w-full mb-2 text-black"
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <button
              type="submit"
              className="bg-orange-500 text-white p-2 w-full"
            >
              Subscribe
            </button>
          </form>
          <div className="flex space-x-4 mt-4">
            <a
              href="https://www.facebook.com/people/Sri-Ramanujar-illam-Trust/100085368250552/"
              className="text-blue-600"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/invites/contact/?i=1qaqcdb5wqy64&utm_content=paq4hvx"
              className="text-pink-600"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/SRI_illam?t=SLtJDK3-J44z1MzdPKbLaQ&s=08" className="text-blue-400">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-yellow-500 text-black py-2 mt-0 ">
        <div className=" mx-auto px-4 flex justify-between items-center max-w-mw ">
          <p>&copy; 2022. All rights reserved.</p>
          <div>
            <a href="/privacy_policy" className="hover:underline">
              Privacy Policy | Terms of use
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
