import React, { useState } from 'react';
import Admin from './Admin';
import {admin_id,admin_password} from "../Config/Firebase_config";
import Navbar from '../Components/Navbar';
function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    // Perform login authentication logic here
    // For simplicity, just set isLoggedIn to true
    if(username==admin_id&&password==admin_password){
      setIsLoggedIn(true);
    }else{
      alert("invalid id or password")
     
    }
    
  };

  return (
    <div className=''>

<div className='fixed w-full top-0 left-0 z-50  bg-black flex items-center justify-end pr-8  overflow-hidden py-4' >
        
        <a href="/">
         <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-black hover:border-orange-600 hover:border-2 active:bg-orange-600 active:border-orange-700">
           Back Home
         </button>
       </a>
        </div>
      {isLoggedIn ? (
        <Admin />
      ) : (
        <>
       
        <div className="max-w-md mx-auto mt-20 p-6 bg-black rounded-lg">
          <div className="mb-4">
            <label htmlFor="username" className="block font-medium mb-1 text-white">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="w-full p-2 border rounded-md bg-white"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block font-medium mb-1 text-white">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full p-2 border rounded-md bg-white"
            />
          </div>
          <button onClick={handleLogin} className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600">
            Login
          </button>
        </div>
        
</>
      )}
    </div>
  );
}

export default Login;
