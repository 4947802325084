import React from 'react';
import Service_card from '../../Components/Service_card';
import Who_are_we from '../../Components/Who_are_we';
import Become_volunteer from '../../Components/Become_volunteer';
import Volunteer2 from '../../Components/Volunteer2';
function Service({navbarHeight}) {
 
  return (
    <div id="service" className="">
      <h2 className='max-w-mw mx-auto flex justify-center text-3xl font-serif sm:px-4' style={{paddingTop:`${navbarHeight+navbarHeight/2}px`}}> We are here with you, Wherever you are...</h2>
      <div className="max-w-mw mx-auto flex flex-col md:flex-row md:flex-wrap md:justify-center py-16 sm:px-4">
        <Service_card img="./service1.png" service="Personal care & Hygiene" />
        <Service_card img="./service2.png" service="Specialized care" />
        <Service_card img="./service3.png" service="Medication Reminders" />
      </div>
      <div className='bg-gray-800'>
      <Who_are_we/>
      </div>
      <div className='max-w-mw mx-auto'>
        <Become_volunteer/>
      </div>
      <div className='max-w-mw mx-auto'>
        <Volunteer2/>
      </div>
    </div>
  );
}

export default Service;
