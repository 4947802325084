import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Table({Data,handleDelete,handleUpdate}) {
  const [rows, setRows] = useState([]);

  useEffect(()=>{
    if(Data&&Data.length>0){
      const cells=Data.map((val,ind)=>({
        id:val.id,
        data:[
          ind+1,
          val.UploadedAt,
          val.ImgUrl || '',
          val.Name || ' ',
          val.Description || ' ',
          'op'
        ]
    }));

    setRows(cells)
    }

    
  },[Data])

const tableHead=[
  {id:1,name:"S.NO"},
  {id:2,name:"Date"},
  {id:3,name:"Image"},
  {id:4,name:"Status"},
  {id:5,name:"Description"},
  {id:6,name:"Edit"}
]



  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false); 
  const [sortOrder, setSortOrder] = useState('');

 
  const handleCellChange = (rowIndex, colIndex, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].data[colIndex] = value;
    setRows(updatedRows);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchQuery('');
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortRows = (rows) => {
    let sortedRows = [...rows];
    switch (sortOrder) {
      case 'name-asc':
        sortedRows.sort((a, b) => a.data[3].localeCompare(b.data[3]));
        break;
      case 'name-desc':
        sortedRows.sort((a, b) => b.data[3].localeCompare(a.data[3]));
        break;
      case 'date-asc':
        sortedRows.sort((a, b) => new Date(a.data[1]) - new Date(b.data[1]));
        break;
      case 'date-desc':
        sortedRows.sort((a, b) => new Date(b.data[1]) - new Date(a.data[1]));
        break;
      default:
        break;
    }
    return sortedRows;
  };

  const filteredRows = isSearchOpen ? rows.filter(row =>
    row.data.some(cell => cell.toLowerCase().includes(searchQuery.toLowerCase()))
  ) : rows;

  const sortedFilteredRows = sortRows(filteredRows);

  return (
    <div className="p-4">
      <div className="flex justify-end items-center mb-4">
        {isSearchOpen && (
          <input 
            type="text" 
            placeholder="Search" 
            value={searchQuery} 
            onChange={handleSearchChange} 
            className="mr-2 p-1 border border-gray-300 rounded"
          />
        )}
        <FontAwesomeIcon icon={faSearch} onClick={toggleSearch} className="cursor-pointer mr-4" />
        <select 
          value={sortOrder} 
          onChange={handleSortChange} 
          className="p-1 border border-gray-300 rounded"
        >
          <option value="">Sort By</option>
          <option value="name-asc">Name Ascending</option>
          <option value="name-desc">Name Descending</option>
          <option value="date-asc">Date Ascending</option>
          <option value="date-desc">Date Descending</option>
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              {tableHead.map((val,ind)=>(
                  <th key={ind} className="border border-black px-4 py-2">{val.name}</th>
              ))}
             
            </tr>
          </thead>
          <tbody>
            {sortedFilteredRows.map((row, rowIndex) => (
              <tr key={row.id}>
                {row.data.map((cell, colIndex) => (
                  <td key={colIndex} className="border border-black px-4 py-2">
                   {colIndex!=2&&colIndex!=5&&<h3>
                     {cell}
                     </h3>} 
                    {colIndex==2&&
                    <img src={cell} className='h-40 w-40 object-contain'/>
                    }

                    {colIndex==5&&
                    <>
                   <button className="bg-red-500 text-white px-7 py-4 rounded-md" onClick={()=>handleDelete(row.id,row.data[2])}>
                     Delete 
                   </button>
                   <br/>
                   <br/>

                   <button className="bg-yellow-500 text-white px-6 py-4 rounded-md" onClick={()=>handleUpdate(row.id,row.data[2],row.data[3],row.data[4])}>
                     Update
                   </button>
                    </>
                    }
      
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
     
    </div>
  );
}

export default Table;
