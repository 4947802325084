import React from 'react';

function Service_card({ img, service }) {
  return (
    <div className='w-full p-8 border-0 border-b-2 bg-white drop-shadow-2xl focus:outline-none transition-shadow shadow-black md:mx-4 mb-8 md:w-1/4 overflow-auto'>
      <div className='flex justify-center'>
        <img src={img} className="w-28 h-28 hover:-translate-y-10 duration-500" alt={service} />
      </div>
      <h2 className="font-semibold text-xl mt-4 text-center">{service}</h2>
    </div>
  );
}

export default Service_card;
