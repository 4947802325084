import React from "react";

const points = [
  { id: 1, text: "Local Volunteers" },
  { id: 2, text: "Monthly Events" },
  { id: 3, text: "Caregiver" },
];

function Volunteer2() {
  return (
    <div className="md:px-28 py-10 px-4 overflow-auto">
      <div className="w-full flex flex-col md:flex-row md:items-center md:justify-center">
        <div className="md:w-1/2 md:mr-20 items-center justify-center">
          <h2 className="text-4xl font-medium ">
            Best way to help your seniors we will help you...
          </h2>
          {points.map((item, ind) => (
            <div className="flex flex-row my-8" key={ind}>
              <img src="./pointer.png" className="w-8 h-8" />
              <h2 className="text-center ml-4 text-2xl font-light">
                {item.text}
              </h2>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center md:ml-20 overflow-hidden">
          <div className="relative">
            
            <div className="w-40 px-60 py-40 -mb-60 -ml-10 bg-orange-400 p-4 rounded-lg shadow-md">
              
            </div>
            <img
              src="./nurse-old-man.png"
              alt="Take Care Image"
              style={{ width: "600px", height: "400px"}}
              className="object-contain"
            />
           
           

            
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Volunteer2;
