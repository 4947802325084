import React from "react";

const Heading = ({ text }) => {
  return (
    <div className="py-3">
      <h2 className="text-white text-3xl">{text}</h2>
    </div>
  );
};

const socialmedia = [
  {
    id: 1,
    name: "fb",
    img: "./facebook.png",
    address:
      "https://www.facebook.com/people/Sri-Ramanujar-illam-Trust/100085368250552/",
  },
  {
    id: 2,
    name: "insta",
    img: "./instagram.png",
    address:
      "https://www.instagram.com/invites/contact/?i=1qaqcdb5wqy64&utm_content=paq4hvx",
  },
  {
    id: 3,
    name: "tweet",
    img: "./twitter.png",
    address: "https://twitter.com/SRI_illam?t=SLtJDK3-J44z1MzdPKbLaQ&s=08",
  },
];

const Content = ({ text }) => {
  return (
    <div>
      <h2 className="text-gray-400 text-lg">{text}</h2>
    </div>
  );
};

function Who_are_we() {
  return (
    <div className="md:px-28 py-10  px-4 overflow-auto max-w-mw mx-auto">
      <h2 className="text-white text-4xl">Who we are?</h2>
      <div className="flex flex-col md:flex-row md:justify-between md:mr-20 ">
        <div className="md:w-1/2 mb-20">
          <Heading text="The main aim of Sri Ramanujar Illam trust" />
          <ul className="list-disc pl-8 text-white">
            <li>
              <Content text="We can help more senior citizens and to provide shelter & clothing for residents of the old age home. To provide medical facilities and health care to the needy peoples." />
            </li>
            <li>
              {" "}
              <Content text="To promote welfare programs, eye camps for women, children, orphans, aged, blind and handicapped persons." />
            </li>
          </ul>
          <Heading text="Challenges:" />
          <Content text="Aging comes with many challenges. The loss of Independence is one potential part of the process as are diminished physical ability and age discrimination." />
          <Heading text="Solution:" />
          <Content text="We are helping old aged & poor elderly in our free old age home by donating food, Health Care, clothings and to provide healthy and friendly atmosphere to senior citizens." />

          <div className="flex flex-row mt-6">
            {socialmedia.map((item, ind) => (
              <div key={ind}>
              <a href={item.address} target="_blank" rel="noopener noreferrer">
                <img
                  src={item.img}
                  className="w-12 h-12 mr-4"
                  alt={item.name}
                />
              </a>
              </div>
            ))}

            <h2 className="text-yellow-400 text-xl text-center ml-4 hover:translate-x-10 duration-300 mt-2">
              <span
                style={{
                  display: "inline-block",
                  transform: "scaleX(2)",
                  marginRight: "10px",
                }}
              >
                ←
              </span>{" "}
              Follow us
            </h2>
          </div>
        </div>
        <div className="flex justify-center items-center md:ml-20">
          <div className="relative">
            <img
              src="./take-care.png"
              alt="Take Care Image"
              style={{ width: "600px", height: "400px" }}
            />

            <div className="w-40 absolute top-0 right-0 -mt-4 -mr-4 bg-white p-4 rounded-lg border border-gray-300 shadow-md">
              <img
                src="./medal.png"
                className="w-16 h-16 mx-auto"
                alt="Medal Icon"
              />
              <h2 className="text-center mt-2 text-sm">
                We have won more than 5+ Awards.
              </h2>
            </div>

            <div className="w-40 absolute bottom-0 left-0 -mb-6 -ml-4 bg-black p-4 rounded-lg shadow-md">
              <h2 className="text-yellow-400 text-center text-4xl">10+</h2>
              <h2 className="text-center mt-2 text-sm text-white">
                Total Caregiver
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Who_are_we;
