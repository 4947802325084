import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Home_intro from '../../Components/Home_intro';
import Footer from './Footer';
function Donate_us() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [purpose, setPurpose] = useState('Food Donation');
  const [message, setMessage] = useState('');
  const [showBankDetails, setShowBankDetails] = useState(false);

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('entry.1626560383', name);   
    formData.append('entry.1176807894', email); 
    formData.append('entry.360718414', phone); 
    formData.append('entry.2134027735', purpose); 
    formData.append('entry.937088200', message); 

    const formUrl = process.env.REACT_APP_DONATE_US_LINK; // Replace FORM_ID with your actual Google Form ID

    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        body: formData,
        mode: 'no-cors', // Make sure to handle CORS appropriately
      });
      setName('');
      setEmail('');
      setPhone('');
      setPurpose('Food Donation');
      setMessage('');
      alert("Form Submitted");

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // Reset form fields after successful submission
     
      console.log('Form submitted successfully:', response);
      // Handle success or further processing here
    } catch (error) {
     
      // Handle error state or show user feedback
    }
    setShowBankDetails(true);
  };

  return (
    <div >
    
   <div className='fixed w-full top-0 left-0 z-50  bg-black flex items-center justify-end pr-8 py-4' >
   <a href="/">
    <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-black hover:border-orange-600 hover:border-2 active:bg-orange-600 active:border-orange-700">
      Back Home
    </button>
  </a>
   </div>
    <div id="donate" className="min-h-screen flex flex-col items-center justify-center bg-gray-100 overflow-auto">
      {!showBankDetails ? (
        <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl">
          <h2 className="text-2xl font-bold mb-6 text-center">Donate our Elders</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-gray-700 font-medium mb-2">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter Your Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required={true}
                />

              </div>
              <div>
                <label htmlFor="purpose" className="block text-gray-700 font-medium mb-2">Purpose</label>
                <select
                  id="purpose"
                  name="purpose"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  required={true}
                >
                  <option value="Food Donation">Food Donation</option>
                  <option value="Diaper">Diaper</option>
                  <option value="Medicine">Medicine</option>
                  <option value="Cash Donation">Cash Donation</option>
                  <option value="Diwali (Sweets, Clothes)">Diwali (Sweets, Clothes)</option>
                </select>
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Message</label>
              <textarea
                id="message"
                name="message"
                className="w-full h-28 px-4 py-2 border border-gray-300 rounded-md"
                placeholder="Write Message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
               
              ></textarea>
            </div>
            <button type="submit" className="w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600 transition duration-300 mt-4">Donate</button>
          </form>
        </div>
      ) : (
        <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl">
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300 mb-6"
            onClick={() => setShowBankDetails(false)}
          >
            Back
          </button>
          <div className="text-center mb-4">
            <h2 className="text-2xl font-bold mb-4">Bank Account Details</h2>
            <img src="/code-value.jpg" alt="QR Code" className="mx-auto mb-4 max-w-xs max-h-40" />
            <p className="text-left">
              <strong>Account Holder:</strong> SRI RAMANUJAR ILLAM TRUST<br />
              <strong>Account No:</strong> 50200072768509<br />
              <strong>IFSC:</strong> HDFC0007321<br />
              <strong>Branch:</strong> Mudichur<br />
              <strong>IFSC:</strong> HDFC0007321
            </p>
          </div>
        </div>
      )}
    </div>
    <Footer/>
    </div>
  );
}

export default Donate_us;