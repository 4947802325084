import Navbar from '../Components/Navbar';
import React, { useState, useEffect,useRef } from 'react';
import { infoDB, imgDB } from '../Config/Firebase_config';
import { v4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { collection, addDoc, getDocs, deleteDoc, doc, getDoc ,updateDoc} from 'firebase/firestore';
import Table from '../Components/Table';

function Data_house() {
    const [name, setName] = useState('');
    const [img, setImg] = useState('');
    const [info, setInfo] = useState('');
    const [data, setData] = useState([]);
    const [file,setFile]=useState(null);
    const [update,setUpdate]=useState({update:false,id:null});
    const fileInputRef = useRef(null);

    const handleUpdate=async(docId,image,title,description)=>{
        setImg(image);
        setName(title);
        setInfo(description);
        setUpdate({update:true,id:docId});  
    }

    const handleUpdated_Upload = async () => {
       

        try {
            const docRef = doc(infoDB, 'InfoDB', update.id);
            let newImgUrl = img;
            let oldImgUrl = '';
            if(!name || !info){
                alert("Please fill all the details");
                return;
               }
                
     
                if(name!="Finished" && name!="Upcoming"){
                 alert("Status should be either Finished or Upcoming");
                 return;
                }

            if (file) {
                // Upload new file
                const newImgRef = ref(imgDB, `Imgs/${v4()}`);
                const uploadFile = await uploadBytes(newImgRef, file);
                newImgUrl = await getDownloadURL(uploadFile.ref);

                // Retrieve the old image URL
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    oldImgUrl = docSnap.data().ImgUrl;
                }
            }

            // Update the Firestore document with the new data
            await updateDoc(docRef, {
                Name: name,
                ImgUrl: newImgUrl,
                Description: info
            });

            // Delete the old image if a new one was uploaded
            if (file && oldImgUrl) {
                const oldFileName = oldImgUrl.split('%2F').pop().split('?')[0];
                const oldImgRef = ref(imgDB, `Imgs/${oldFileName}`);
                await deleteObject(oldImgRef);
            }

            alert('Document updated successfully');
            setImg('');
            setName('');
            setInfo('');
            setFile(null);
            if(fileInputRef.current){
                fileInputRef.current.value=null;
            }
            setUpdate({ update: false, id: null });
            getData(); // Refresh data to include the updated document
        } catch (error) {
            alert('Error updating document: ' + error);
        }
    };
    

    const handleUpload = async (f) => {
        const imgs = ref(imgDB, `Imgs/${v4()}`);
        try {
            const uploadFile = await uploadBytes(imgs, f);
            const imgUrl = await getDownloadURL(uploadFile.ref);
            
            setImg(imgUrl);
            return imgUrl;
        } catch (err) {
            console.error("Error uploading image", err);
        }
    }

    const getData = async () => {
        const valRef = collection(infoDB, "InfoDB");
        const dataDb = await getDocs(valRef);
        const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));
        setData(allData);
    }

    useEffect(() => {
        getData();
    }, [img]);

    const handleClick = async () => {
          if(!name || !info){
           alert("Please fill all the details");
           return;
          }
           if(!file){
            alert("Please select a image file")
            return;
           }

           if(name!="Finished" && name!="Upcoming"){
            alert("Status should be either Finished or Upcoming");
            return;
           }
           if(data.length>=20){
            alert("You are exceeding your maximum limit of uploading");
            return;
           }
          
        const imgUrl= await handleUpload(file);
        const valRef = collection(infoDB, 'InfoDB');
        
        // Get the current date
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
        const day = String(now.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        await addDoc(valRef, {
            Name: name,
            ImgUrl: imgUrl,
            Description: info,
            UploadedAt: formattedDate // Add the formatted date
        });
        alert("Data added successfully");
        setImg('');
            setName('');
            setInfo('');
            setFile(null);
            if(fileInputRef.current){
                fileInputRef.current.value=null;
            }
        getData(); // Refresh data to include the newly added document
    }

    const handleDelete = async (docId, imgUrl) => {
        if(data.length<=3){
            alert("There should be minimum of three data in blogs!")
            return;
        }

        const confirmDelete = window.confirm("Are you sure you want to delete this file?");
    
        if (!confirmDelete) {
            return; // Cancel deletion if user clicks Cancel in the confirmation dialog
        }
    
        try {
            const docRef = doc(infoDB, "InfoDB", docId);
            const docSnap = await getDoc(docRef);
            const docData = docSnap.data();
    
            if (docData.ImgUrl) {
                const fileName = imgUrl.split('%2F').pop().split('?')[0];
                const imgRef = ref(imgDB, `Imgs/${fileName}`);
                await deleteObject(imgRef);
            }
    
            await deleteDoc(docRef);
            alert("Data deleted successfully");
            getData();
        } catch (error) {
            console.error("Error deleting data:", error);
            alert(`Error deleting data: ${error.message}`);
        }
    };
    

    return (
        <div>
           

            <div className="flex justify-between mx-10 my-10 py-16">
                <div></div>
                {!update.update&&
                <button onClick={handleClick} className="bg-orange-500 text-white px-6 py-4 rounded-md hover:bg-orange-600">
                Upload
            </button>
            }
                
            </div>

            <div className="max-w-md mx-auto mt-20 p-6 bg-black rounded-lg">
                <div className="mb-4">
                    <label htmlFor="image" className="block font-medium mb-1 text-white">Image</label>
                    <input
                        type="file"
                        id="image"
                        ref={fileInputRef}
                        onChange={(e) => setFile(e.target.files[0])}
                        className="w-full p-2 border rounded-md bg-white"
                        required={true}
                    />
                    <br></br>
                    <br></br>
                    <label htmlFor="imgname" className="block font-medium mb-1 text-white">Status(Finished/Upcoming)</label>
                    <input
                        type="text"
                        id="imgname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white"
                        required={true}
                    />
                    <br></br>
                    <br></br>
                    <label htmlFor="description" className="block font-medium mb-1 text-white">Description</label>
                    <textarea
                        id="description"
                        value={info}
                        onChange={(e) => setInfo(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white"
                        required={true}
                    />
                    <br/>
                    <br/>
                    {update.update&&<div className='flex justify-between'>
                    <button onClick={handleUpdated_Upload} className="bg-orange-500 text-white px-6 py-4 rounded-md hover:bg-orange-600">
                    Update
                </button>
                <button onClick={() => window.location.reload()} className="bg-orange-500 text-white px-6 py-4 rounded-md hover:bg-orange-600">
                    No Change
                </button>
                </div>}
                    
                </div>
            </div>

            <div className="mx-10 my-10">
                <Table Data={data} handleDelete={handleDelete} handleUpdate={handleUpdate}/>
            </div>
        </div>
    )
}

export default Data_house;
