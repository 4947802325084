import React, { useState } from 'react';
import Input_Box from '../../Components/Input_Box';
import Footer from './Footer';
function Contact_us({navbarHeight}) {
 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('entry.1947676356', name);   // Replace with actual entry ID for Name field
    formData.append('entry.1776196976', email);  // Replace with actual entry ID for Email field
    formData.append('entry.724705013', address); // Replace with actual entry ID for Address field

    const formUrl = process.env.REACT_APP_CONTACT_US_LINK; // Replace FORM_ID with your actual Google Form ID

    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        body: formData,
        mode: 'no-cors', // Make sure to handle CORS appropriately
      });
      setName('');
      setEmail('');
      setAddress('');
      alert("Form Submitted");

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // Reset form fields after successful submission
     
      console.log('Form submitted successfully:', response);
      // Handle success or further processing here
    } catch (error) {
     
      // Handle error state or show user feedback
    }
  };

  return (
    <div >
    <div id="contact_us" className='md:px-28  max-w-mw mx-auto sm:px-4' >
      <h2 className='text-center text-3xl' style={{paddingTop:`${navbarHeight+navbarHeight/2}px`}}>Contact Us</h2>
      <form onSubmit={handleSubmit}>
      <div className='flex flex-col md:flex-row md:justify-between my-5'> 
        <div className='w-full md:w-full md:mr-5'>
          <Input_Box type="text" htmlFor="name" label="Name" id="nameinfo" value={name} onchange={setName} />
        </div>
        <div className='w-full md:w-full md:ml-5'>
          <Input_Box type="email" htmlFor="email" label="Email" id="emailinfo" value={email} onchange={setEmail} />
        </div>
      </div>
      <div className='w-full'>
        <label htmlFor="address" className='text-lg font-semibold my-2'>Address</label>
        <textarea
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full h-28 p-2 border-0 border-b-2 border-orange-400 bg-white drop-shadow-2xl focus:outline-none transition-shadow shadow-black"
          required={true}
        />
      </div>
      <div className="flex justify-end mt-5 mb-8">
        <button type="submit" className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-white hover:border-orange-600 hover:border-2 hover:text-orange-600">
          Submit
        </button>
      </div>
      </form>
      
    </div>
    <Footer/>
    </div>
    
  );
}

export default Contact_us;
