import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import Home_intro from "../../Components/Home_intro";
const Home = ({ navbarHeight }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div id="home" className="pt-0 bg-black min-h-screen w-full">
      <Home_intro navbarHeight={navbarHeight} />

      <div className="relative bg-gray-100 text-black pt-4  md:px-28 ">
        <div className="mx-auto max-w-mw px-4 md:px-2">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2 p-1">
              <div className="flex flex-wrap">
                {["/aimg1.png", "/aimg2.png", "/aimg3.png"].map(
                  (src, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 2 ? "w-full" : "w-1/2"
                      } p-1 overflow-hidden`}
                    >
                      <img
                        src={src}
                        alt={`Elders Care ${index + 1}`}
                        className={`w-full h-auto rounded-md ${
                          isHovered ? "zoom-image " : ""
                        }`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="w-full  md:w-1/2 p-2 flex flex-col justify-center md:ml-2 mb-6 overflow-auto">
              <h2 className="text-xl md:text-3xl font-bold text-left mb-4">
                About us
              </h2>
              <p className="mb-2  text-left">
                Sri Ramanujar Illam trust - Better for Elder
              </p>
              <p className="mb-2  text-left">
                "Senior Care" - living with a new perspective
              </p>

              <p className="text-left mb-2">
                Sri Ramanujar Illam old age home is the registered NGO which
                takes care of destitute elders. We are planning our own old age
                home for a neglected destitute elderly person of hundred
                inmates, where elderly people, handicap and orphans will be
                sheltered with sufficient space homely atmosphere with Medical
                and facilities including food and clothing.
              </p>

              <p className="mb-2  text-left font-bold">Vision</p>
              <p className="mb-2  text-left">
                1. To provide First class service to all Homemates/Elders having
                Physical/Mental Health problems.
              </p>
              <p className="mb-2  text-left">
                2. To provide Excellent care Possible for all Homemates/Elders
                and become the best old age home in the state
              </p>
              <p className="mb-2  text-left font-bold">Mission</p>
              <p className="mb-2  text-left">
                1. To provide Good shelter and care to all Elders/Homemates
                admitted in SRI old age home.
              </p>
              <p className="mb-2  text-left">
                2. Best Team working to improve Elders/Homemates care in all the
                areas
              </p>
              <div className="mt-6 flex  md:flex-row flex-col  md:justify-around  overflow-auto">
                {["25+ Volunteers", "100k+ Donations", "25+ Sponsors"].map(
                  (text, index) => (
                    <div
                      key={index}
                      className="text-center bg-white p-8 rounded-md shadow-lg sm:mb-2"
                    >
                      <span className="text-xl text-orange-500">{text}</span>
                    </div>
                  )
                )}
              </div>
              <div className="mt-6 text-center">
                <button
                  className="px-6 py-3 bg-secondary text-black font-semibold rounded-md hover:bg-orange-500 hover:text-white transition duration-300"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSejUTD9BsKCrT_nAi4ARU9-e5mMJBmuf1KJheEd6zyqj93FwA/viewform?usp=sf_link",
                      "_blank"
                    )
                  }
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
