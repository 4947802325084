import React from 'react'
import Footer from './Footer'
function Privacy_policy({navbarHeight}) {
  return (
    <div >
    
   <div className='fixed w-full top-0 left-0 z-50  bg-black flex items-center justify-end pr-8 py-4' >
   <a href="/">
    <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-black hover:border-orange-600 hover:border-2 active:bg-orange-600 active:border-orange-700">
      Back Home
    </button>
  </a>
   </div>

   
    <div>
    <div className=" px-4 sm:px-6 lg:px-8 overflow-auto mx-auto">
      <h1 className="text-3xl font-bold mb-2 overflow-auto pt-20 " >Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>
          Welcome to Sriramanujarillam official website. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://sriramanujarillam.org/" className="text-blue-500 underline">https://sriramanujarillam.org/</a>.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
        <p>
          We collect the following information from users who enter their details on our website:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>Personal Identification Information: Name, email address, phone number, postal address.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
        <p>
          We use the information we collect for the following purposes:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li>To respond to your inquiries and provide customer support.</li>
          <li>To process your donations or other transactions.</li>
          <li>To send you updates, newsletters, and other information related to our NGO’s activities.</li>
          <li>To improve our website and tailor user experience.</li>
          <li>To comply with legal obligations and resolve any disputes.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. How We Share Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal identification information to outside parties except as described below:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
        <p>
          We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Your Data Protection Rights</h2>
        <p>
          Depending on your location, you may have the following rights regarding your personal data:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li><strong>The Right to Access:</strong> You have the right to request copies of your personal data that we hold. This means you can ask us to provide you with a copy of the information we have about you, so you can see exactly what data we have collected and are processing.</li>
          <li><strong>The Right to Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or incomplete. If you find that the personal data we hold about you is incorrect or missing important information, you can ask us to update or complete this data to ensure it is accurate and up-to-date.</li>
        </ul>
      </section>
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Privacy_policy