import React,{useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Admin from './Pages/Admin';
import Navbar from './Components/Navbar';
import Home from './Pages/Main_web/Home';
import Service from './Pages/Main_web/Service';
import Blog from './Pages/Main_web/Blog';
import Contact_us from './Pages/Main_web/Contact_us';
import Donate_us from './Pages/Main_web/Donate';
import Privacy_policy from './Pages/Main_web/Privacy_policy';
function App() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  
  return (
    <div className="font-serif bg-gray-100">
      <Router>
       
        <Routes>
       
          <Route path="/" element={
            <>
             <Navbar navbarHeight={navbarHeight} setNavbarHeight={setNavbarHeight}/>
              <Home navbarHeight={navbarHeight} />
              <Service navbarHeight={navbarHeight}/>
              <Blog navbarHeight={navbarHeight}/>
              <Contact_us navbarHeight={navbarHeight}/>
            </>
          } />
          <Route path="/admin_login_sri" element={<Login />} />
          <Route path="/donate" element={<Donate_us />} />
          <Route path="/privacy_policy" element={<Privacy_policy navbarHeight={navbarHeight} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
