import React from 'react'
import { FaPlay } from 'react-icons/fa';
function Home_intro({navbarHeight}) {
  return (
   <div className="h-screen max-w-mw mx-auto flex flex-col  items-start justify-center text-white px-8 relative overflow-auto" style={{paddingTop:navbarHeight}}>
    <div
      className="absolute inset-0 bg-contain bg-right bg-no-repeat opacity-50"
      style={{ backgroundImage: "url('/bgimg.png')" }}
    ></div>
    <div className="relative z-10  mx-auto md:ml-8  p-4 overflow-auto">
      <h1 className="text-5xl md:text-7xl font-bold text-left text-gray-700 mb-4">LIVE IN CARE</h1>
      <h1 className="text-3xl md:text-5xl font-bold text-left  mb-8">
        Each <span className="text-yellow-500">WRINKLES</span> has a <br />
        <span className="text-yellow-500">STORY</span> to tell us...
      </h1>
      <p className="text-lg mb-8">Let us all help make life better for those who made our life better.</p>
      <div className="flex items-center mb-12">
        <a
          href="https://www.youtube.com/channel/UC_aedqNYKgQEM4yCiAM60xA"
          target="_blank"
          rel="noopener noreferrer"
          className="px-6 py-3 bg-secondary text-orange-500 font-semibold flex items-center"
        >
          <FaPlay className="text-orange-500 mr-2" /> Watch Video
        </a>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
        {['Your Donation for the people cause you care means a lot to us.', 'We cost every single amount of Donation to elderly care.', 'We provide quality in home health care for the elderly and chronically illness.'].map((text, index) => (
          <div key={index} className="flex items-start text-gray-500 hover:text-white">
            <div>
              <h2 className="text-xl md:text-3xl font-bold mb-2">{index + 1}.</h2>
              <p>{text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

export default Home_intro