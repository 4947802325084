import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { infoDB } from '../../Config/Firebase_config';
import { getDocs, collection } from 'firebase/firestore';
import PropTypes from 'prop-types';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css"

const Slide = ({ status, imageSrc, description, onClick }) => (
  <div className="text-center px-2">
    <div className="relative bg-gray-200 rounded-lg shadow-md mb-4">
      <div
        className={`absolute top-2 left-2 font-bold px-2 py-1 opacity-100 transition-opacity duration-300 z-10 ${
          status === 'Upcoming' ? 'bg-yellow-500 text-black' : 'bg-red-500 text-black'
        }`}
      >
        {status}
      </div>
      <div className="overflow-hidden">
        <img
          src={imageSrc}
          alt={description}
          className="mx-auto cursor-pointer w-full h-64 object-cover transform transition-transform duration-300 hover:scale-110 rounded-lg"
          onClick={() => onClick(imageSrc)}
        />
      </div>
      <div className="p-2 max-h-12 overflow-auto hide-scrollbar">
        <p className="mt-2 font-bold text-center">
          {description}
        </p>
      </div>
    </div>
  </div>
);

Slide.propTypes = {
  status: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Blog({navbarHeight}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [slideData, setSlideData] = useState([]);
  
  
  useEffect(() => {
    const getData = async () => {
      const valRef = collection(infoDB, "InfoDB");
      const dataDb = await getDocs(valRef);
      const allData = dataDb.docs.map(val => ({ ...val.data(), id: val.id }));

      if (allData && allData.length > 0) {
        const slides = allData.map(val => ({
          status: val.Name,
          imageSrc: val.ImgUrl,
          description: val.Description
        }));
        setSlideData(slides);
      }
    };

    getData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };


  

  const openImage = (imageSrc) => {
    setSelectedImage(imageSrc);
    
  };

  const closeImage = () => {
    setSelectedImage(null);
    
  };

  return (
    <div id="blog" className="px-8  max-w-mw mx-auto"  >
     <h2 className="text-4xl font-medium mb-8" style={{paddingTop:`${navbarHeight+navbarHeight/2}px`}}>
     Our Blogs and Stories

          </h2>
      <Slider {...settings}>
        {slideData.map((slide, index) => (
          <Slide
            key={index}
            status={slide.status}
            imageSrc={slide.imageSrc}
            description={slide.description}
            onClick={openImage}
          />
        ))}
      </Slider>
<div className='pt-8 flex justify-end'>
      <button  onClick={()=>window.open('https://drive.google.com/drive/folders/1e7bknrjUV9cUBCIaU22zl3aEvAaptHqr')} className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-black hover:border-orange-600 hover:border-2 active:bg-orange-600 active:border-orange-700">
          More Stories
         </button>
         </div>
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative">
            <button className="absolute top-0 right-0 mt-2 mr-2 text-white text-3xl" onClick={closeImage}>&times;</button>
            <img src={selectedImage} alt="Selected" className="max-h-screen" />
            
          </div>
        </div>
      )}
     
     

    </div>
  );
}

export default Blog;
