import React from 'react'

function Input_Box({htmlFor,label,id,value,onchange,placeholder,type}) {
  return (
    <div >

<label htmlFor={htmlFor} className='text-lg font-semibold my-2'>{label}</label>
      <input
  type={type}
  id={id}
  value={value}
  onChange={(e) => onchange(e.target.value)}
  placeholder={placeholder}
  className="w-full p-2 border-0 border-b-2 border-orange-400 bg-white drop-shadow-2xl focus:outline-none transition-shadow shadow-black"
  required={true}
/>
    </div>
  )
}

export default Input_Box
