import React, { useState } from "react";
import Input_Box from "./Input_Box";
function Become_volunteer() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('entry.9344886', name);   // Replace with actual entry ID for Name field
    formData.append('entry.1587727290', email);  // Replace with actual entry ID for Email field
   

    const formUrl = process.env.REACT_APP_VOLUNTEER_LINK; // Replace FORM_ID with your actual Google Form ID

    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        body: formData,
        mode: 'no-cors', // Make sure to handle CORS appropriately
      });
      setName('');
      setEmail('');
      alert("Form Submitted");

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // Reset form fields after successful submission
     
      console.log('Form submitted successfully:', response);
      // Handle success or further processing here
    } catch (error) {
     
      // Handle error state or show user feedback
    }
  };


  return (
    <div className="md:px-28 py-10 px-4 overflow-auto">
      <div className="w-full flex flex-col md:flex-row md:items-center md:justify-center">
        <div className="md:w-1/2   md:mr-20 items-center justify-center ">
          <div className="flex flex-row ">
            <div
              className="w-40 h-40 bg-cover bg-center mr-10 flex items-center justify-center "
              style={{ backgroundImage: "url('./dot-bg.png')" }}
            >
              <img src="./profile1.jpg" className="w-32 h-32 rounded-full " />
            </div>
            <div
              className="w-52 h-52 bg-cover bg-center ml-12 flex items-center "
              style={{ backgroundImage: "url('./line-bg.png')" }}
            >
              <img
                src="./profile3.jpg"
                className="w-56 h-56 rounded-full mt-40"
              />
            </div>
          </div>
          <img src="./profile2.jpg" className="w-40 h-40 rounded-full" />
        </div>
        <div className="flex flex-col md:justify-center md:items-center sm:mt-10">
          <h2 className="text-4xl font-medium">
            Become a <span className=" text-red-500">Volunteer.</span>
          </h2>
          <form onSubmit={handleRegister}>
          <div className="mt-6 flex md:flex-row flex-col items-center justify-between ">
            <div className="mr-4 sm:w-full sm:mb-4">
              <Input_Box
               type="text"
                htmlFor="name"
                id="nameinfo"
                value={name}
                onchange={setName}
                placeholder="Your name"
              />
            </div>
            <div className="mr-4 sm:w-full sm:mb-4">
              <Input_Box
              type="email"
                htmlFor="email"
                id="emailinfo"
                value={email}
                onchange={setEmail}
                placeholder="Your email"
              />
            </div>
            <button type="submit" className="bg-orange-500 text-white p-4  rounded-md hover:bg-white hover:border-orange-600 hover:border-2 hover:text-orange-600">
              Register
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Become_volunteer;
